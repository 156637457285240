import React, { Suspense } from 'react';
import ErrorBoundary from './ErrorBoundary';
import AbsoluteLoader from './components/Loading';
const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));
const App: React.FC = () => {
  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);
  return (
    <Suspense fallback={<AbsoluteLoader />}>
      <ErrorBoundary
        fallback={
          <h2>
            Something went wrong. Please try again or contact your Account Manager with any
            questions.
          </h2>
        }
      >
        {localStorage.getItem('authorization') ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;

import React from 'react';

interface ErrorProps {
  fallback: JSX.Element;
}
interface ErrorState {
  hasError: boolean;
  error: React.ReactNode;
}
class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error: React.ReactNode) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { userQuery } from './__generated__/userQuery';
interface State {
  user: userQuery | undefined;
  logout: () => void;
}
type AuthProviderProps = { children: React.ReactNode };
const AuthContext = React.createContext<State | undefined>(undefined);
const QUERY_USER = gql`
  query userQuery {
    user {
      id
      firstName
      lastName
      email
      type
      operatingCompanyId
    }
  }
`;
function AuthProvider({ children }: AuthProviderProps) {
  const { data: user } = useQuery<userQuery>(QUERY_USER);
  const logout = () => {
    localStorage.removeItem('authorization');
    window.location.reload();
  };

  return <AuthContext.Provider value={{ user, logout }}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };

import React from 'react';
import { CircularProgress } from '@material-ui/core';

const AbsoluteLoader = () => {
  return (
    <CircularProgress
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    />
  );
};

export default AbsoluteLoader;

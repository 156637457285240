import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { AuthProvider } from './context/auth-context';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import WalsheimPro from './fonts/gtwalsheimpro-regular-webfont.woff';

const walsheim = {
  fontFamily: 'Walsheim',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(${WalsheimPro}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Walsheim, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [walsheim],
      },
    },
  },
  palette: {
    primary: {
      main: '#2F333D',
    },
    secondary: {
      main: '#DA291C',
    },
  },
});

const useStyles = makeStyles({
  '@global': {
    '@font-face': [walsheim],
    ':root': {
      backgroundColor: '#E5E5E5',
      margin: 0,
      padding: 0,
    },
  },
});
export default function GlobalCss() {
  useStyles();

  return <div className="cssjss-advanced-global-root"></div>;
}
const authToken = localStorage.getItem('authorization')
  ? `Token ${localStorage.getItem('authorization')}`
  : undefined;
const getCSRFToken = () => {
  const splitCookies = decodeURIComponent(document.cookie).split(';');
  let csrfToken;
  splitCookies.forEach((cookie) => {
    if (cookie.indexOf('csrftoken') !== -1) {
      csrfToken = cookie.split('=')[1];
    }
  });
  return csrfToken;
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  headers: {
    Authorization: authToken,
    'X-CSRFToken': getCSRFToken(),
  },
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <AuthProvider>
        <GlobalCss />
        {/* <CssBaseline /> */}
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);
